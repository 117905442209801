<template>
  <div class="home">
    <div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
<!--    <section class="" style="height: calc(100vh - 70.4px);">-->
<!--      <div id="carouselExampleIndicators" style="height: calc(100vh - 70.4px);" class="carousel slide" data-bs-ride="carousel">-->
<!--        <div class="carousel-indicators">-->
<!--          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>-->
<!--          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>-->
<!--          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>-->
<!--        </div>-->
<!--        <div class="carousel-inner h-100">-->
<!--          <div class="carousel-item active overflow-hidden h-100 "  >-->
<!--            <div class="position-absolute w-100 h-100" style="z-index: -1;filter: blur(8px);-webkit-filter: blur(8px);background-repeat: no-repeat;background-size: cover" :style="{'background-image': 'url(' + require('../assets/images/successful-medical.jpg') + ')'}"></div>-->
<!--            <h1 class="p-5">We are here to help</h1>-->
<!--          </div>-->

<!--          <div class="carousel-item overflow-hidden">-->
<!--            <h1>Helloooo</h1>-->
<!--&lt;!&ndash;            <img src="@/assets/images/successful-medical.jpg"  height="100px" class="d-block w-100 overflow-hidden" alt="...">&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <carousel></carousel>
<!--    <div class="p-4">-->
<!--    <div class="container">-->
<!--      <div class="row justify-content-md-center">-->
<!--        <div class="col-md-6 landing-page__search-column">-->
<!--          <div id="fh-search-form" class="search-box search-box&#45;&#45;primary" style="border-radius: 5px;">-->
<!--            <div id="fh-search-mode-switcher" class="tab-switcher">-->
<!--              <h4 class="tab-switcher__option tab-switcher__option&#45;&#45;active">{{ $t("Select login method") }}</h4>-->
<!--            </div>-->
<!--            <div class="search-box__footer">-->
<!--              <br>-->
<!--              <div class="d-grid gap-2">-->
<!--                <router-link class="btn btn-primary" to="/login?type=employee">{{ $t("Partners") }}</router-link>-->
<!--                <router-link class="btn btn-primary" to="/login?type=client">{{ $t("Clients") }}</router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    </div>-->
    <section class="section section-lg pb-0">
      <stats-component></stats-component>
    </section>
    <section id="about" class="section section-lg">
      <div class="">
        <card
            no-body
            shadow-size="lg"
            class="border-0 bg-primary"
            style="border-radius: 0;"
        >
          <div id="about-us" class="p-5">
            <div class="row align-items-center justify-content-md-center">
              <div class="col-md-9 text-md-center">
                <div class="row align-items-center">
                  <div class="col-lg-12">
                    <h3 class="text-white pb-2">{{ $t("About us") }}</h3>
                    <p class="lead text-white mt-3">
                      {{ $t("ABOUT_US_DESC") }}<br>
                      <br>
                      {{ $t("ABOUT_US_DESC_SEC") }}
                    </p>

                    <img width="300" src="@/assets/logo_allcare.png" >
                  </div>

                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <section id="positions" class="section section-lg pt-2">

      <div id="shift-rates" class="container pt-lg">
        <h2 class="pb-3">{{ $t("Shift positions and rates") }}</h2>
        <card

            no-body
            shadow-size="lg"
            class="border-0 "
            style="background: linear-gradient(180deg, #eaeced, white);
    overflow: hidden;">
          <div class="table-responsive">
          <table class="table  table-primary table-hover">
        <thead class="bg-primary text-white fw-bolder m-3">
          <th></th>
          <th>{{ $t("Day Rate") }}</th>
          <th>{{ $t("Night Rate") }}</th>
          <th>{{ $t("Holiday Rate") }}</th>
          <th>{{ $t("Holiday Night Rate") }}</th>
        </thead>
          <tbody>
        <tr v-for="position in positions" :key="position">
          <th class="text-start">{{ position.name }}</th>
          <td>{{ getPositionCostString(position.normalDayHourlyRate) }}</td>
          <td>{{ getPositionCostString(position.normalNightHourlyRate) }}</td>
          <td>{{ getPositionCostString(position.holidayDayHourlyRate) }}</td>
          <td>{{ getPositionCostString(position.holidayNightHourlyRate) }}</td>
        </tr>
          </tbody>
      </table>
          </div>
        </card>
        <p class="text-end pt-2">*{{ $t("The rate does not include the company's commission") }}</p>
      </div>

    </section>
    <section class="" >
      <card
          no-body
          shadow-size="lg"
          class="border-0 "
          style="border-radius: 0;background: linear-gradient(180deg, #eaeced, white);"
      >
        <div class="p-5 ">
          <div class="row align-items-center justify-content-md-center">
            <div class="col-md-9 text-md-start">
              <div class="row align-items-center">
                <div class="col-lg-7">
                  <h4 class="mb-1 text-primary fw-bold"> {{ $t("Thank you for your support!") }}</h4>
                </div>
                <div class="col-lg-5 me-lg-auto my-lg-auto text-lg-end mt-5">
                  <base-button target="_blank" href="https://www.facebook.com/profile.php?id=61552681300035" icon="fa-brands fa-facebook" class="btn btn-primary border-0" tag="a"> Facebook</base-button>
                  <base-button target="_blank" href="https://www.instagram.com/allcare.cyprus/" icon="fa-brands fa-instagram" class="btn btn-primary border-0 ms-2" tag="a"> Instagram</base-button>
                  <!--                    <base-button icon="fa-brands fa-instagram" class="btn btn-primary instagram border-0 ms-2" tag="a"> Instagram</base-button>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </section>
    <contact-us></contact-us>
    <section class="section section-lg pb-0">
      <div class="">
        <card
            no-body
            shadow-size="lg"
            class="border-0 bg-primary"
            style="border-radius: 0;"
        >
          <div class="p-5">
            <div class="row align-items-center justify-content-md-center">
              <div class="col-md-12 text-md-start ps-4 pe-4">
                <div class="row align-items-center ps-3 pe-3">
                  <div class="col-lg-8">
                    <h3 class="text-white">
                      Fast <i class="fa fa-circle font-size-xs" style="top: -3px;position: relative;"></i>
                      Easy <i class="fa fa-circle font-size-xs" style="top: -3px;position: relative;"></i>
                      Flexible
                    </h3>
                  </div>
                  <div class="col-lg-4 ml-lg-auto text-md-end">
                    <p class="lead text-white mt-3">
                      {{ $t("All Cyprus, one network") }}<br>
                      {{ $t("Join us today!") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
</div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import {helpers, required} from "@vuelidate/validators"
import Card from "@/components/Card"
import BaseButton from "@/components/BaseButton"
import Carousel from "@/components/Carousel"
import ContactUs from "@/views/ContactUs"
import {getAnalytics, logEvent} from "firebase/analytics"
import StatsComponent from "@/components/StatsComponent.vue"

export default {
  name: "home-iew",
  components: {StatsComponent, ContactUs, Carousel, BaseButton, Card},
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {

    }
  },
  data() {
    return {
      availableRoutes: [],
      positions: [],
      model: {
        persons: 2,
        departure: null,
        destination: null,
        returnDatetime: null,
        depDatetime: null
      }
    }
  },
  computed: {
    departureOptions() {
      console.log("destination", this.model.destination)
      let routes = JSON.parse(JSON.stringify(this.availableRoutes))
      if (this.model.destination) {
        let index = routes.findIndex(r => r.key === this.model.destination.key)
        if (index >= 0) {
          routes[index]["$isDisabled"] = true
        }
      }
      return routes
      // return this.availableRoutes.filter(function(el) { return el.key !== selected }, this);
    },
    destinationOptions() {
      let routes = JSON.parse(JSON.stringify(this.availableRoutes))
      if (this.model.departure) {
        let index = routes.findIndex(r => r.key === this.model.departure.key)
        if (index >= 0) {
          routes[index]["$isDisabled"] = true
        }
      }
      return routes
    }
  },
  async mounted() {
    const analytics = getAnalytics()
    logEvent(analytics, "page_view", {
      page_title: this.$route.name
    })
    // if (this.$store.state.routes) {
    //   this.availableRoutes = this.$store.state.routes
    // } else {
    //   this.availableRoutes = await this.$store.dispatch("getRouteList")
    // }
    await this.getPositions()
  },
  methods: {
    getPositionCostString(cost){
      if (cost === 0){
        return "-"
      }
      return "€" + cost/100 + "/h"
    },
    async getPositions() {
      this.positions = await this.$store.dispatch("fetchAllPositions", true)

      for (let i = 0; i < this.positions.length; i++) {
        const position = this.positions[i]
        if (position.id.includes("TEST_POSITION")) {
          this.positions.splice(i, 1)
        }
      }
      this.positions.sort((a,b) => a.normalDayHourlyRate - b.normalDayHourlyRate)
    }
  },
}
</script>
<style scoped>
.booking-flow__tab {
  margin-top: 50px;
}

@media only screen and (min-width: 992px) {
  .langing-page__search-column {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/*.search-box--primary {*/
/*  margin-left: 50px;*/
/*  max-height: 1500px;*/
/*}*/

.search-box {
  background-color: #fffffff0;
  border-radius: 0;
  border: 1px solid transparent;
  padding-top: 20px;
  padding-bottom: 32px;
  padding-left: 36px;
  padding-right: 36px;
  /*   max-height: 430px;
  min-height: 430px; */
  overflow-y: hidden;
  margin-bottom: 0;
  position: relative;
  box-shadow: 3px 4px 9px 0 rgb(0 0 0 / 16%);
}

.landing-page-sidebox {
  font-family: averta, sans-serif;
}

.search-box--secondary {
  margin-right: 50px;
}

.search-state-indicator {
  min-height: 30px;
  position: absolute;
  top: 235px;
  width: 14px;
  margin-left: -13px;
  margin-top: -2px;
  overflow: hidden;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 992px) {
  .langing-page__search-column {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.typeahead-container {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  height: 40px;
}

.search-box input {
  box-shadow: none !important;
  border: none;
  text-align: left;
  padding-left: 0;
}

.booking-flow__tab {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1030px;
  }
}

.search-box__header--primary {
  font-weight: 700;
  color: #212121;
  font-size: 24px;
}

.search-box__header--centered {
  text-align: center;
}

.search-box__header {
  font-weight: 600;
  color: #212121;
  font-size: 20px;
  cursor: default;
  margin: 0;
  padding-bottom: 6px;
  display: block;
  overflow: hidden;
}

.search-state-indicator {
  min-height: 30px;
  position: absolute;
  top: 235px;
  width: 14px;
  margin-left: -13px;
  margin-top: -2px;
  overflow: hidden;
  transition: all 0.2s ease;
}

.search-state-indicator.highlight:before,
.search-state-indicator:before {
  border-left: 0;
  border-bottom: 0;
  box-shadow: none;
  border-bottom-color: #fff;
  content: " ";
  display: block;
  width: 19px;
  height: 19px;
  margin-top: 4px;
  margin-left: 4px;
  transform: rotate(45deg);
  border-radius: 0;
  border: 1px solid #fff;
}

.search-state-indicator:before {
  background-color: #fff;
}

body {
  background: #0080804a;
}

.search-box__footer {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.location-selection-list__item {
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  padding: 4px 5px 4px 20px;
  font-size: 16px;
  line-height: 30px;
  position: relative;
  color: #212121;
}

button.list-group-item.list-group-item-action.location-selection-list__item:hover {
  color: #0080808a;
}

.col-md-6.langing-page__search-column {
  margin: 0 auto;
}

.table thead th {
  padding: 15px;
  font-size: 15px;
}

th.text-start {
  font-size: 15px;
}
</style>

